import api from '../services/index'

const API_BASE_URL = '/api/bookings';

export const fetchBookings = async () => {
    try {
        const response = await api.get(API_BASE_URL);
        return response.data;
    } catch (error) {
        console.error("Error fetching bookings:", error);
        throw error;
    }
};

export const createBooking = async (booking) => {
    try {
        const response = await api.post(API_BASE_URL, booking);
        return response.data;
    } catch (error) {
        console.error("Error creating booking:", error);
        throw error;
    }
};

export const deleteBooking = async (bookingId) => {
    try {
        await api.delete(`${API_BASE_URL}/${bookingId}`);
    } catch (error) {
        console.error("Error deleting booking:", error);
        throw error;
    }
};

export const createBookingCheck = async (booking) => {
    try {
        const response = await api.post(`${API_BASE_URL}/check`, booking)
        return response.data;
    } catch (error) {
        console.error("Error creating booking:", error);
        throw error;
    }
}