import React, { useEffect, useRef, useState } from 'react';
import { Container, Typography, Button, Box, Paper, TextField, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import emailjs from '@emailjs/browser';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { t } from 'i18next';
import './index.scss'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Label } from '@mui/icons-material';
import { createBookingCheck } from '../../services/booking';

const Booking = ({ setRedirectToSuccess, roomType }) => {
    const navigate = useNavigate();
    const [firstName, setFirstName] = useState('');
    const [surname, setSurName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [fromDate, setFromDate] = React.useState(dayjs(new Date()));
    let today = new Date(); // Get today's date
    today.setDate(today.getDate() + 1);
    const [toDate, setToDate] = React.useState(dayjs(today));
    const [showErrorMessage, setShowErrorMessage] = React.useState(false);
    const [roomTypeValue, setRoomTypeValue] = React.useState('');
    const fromDateFormated = new Date(fromDate.toDate());
    const toDateFormated = new Date(toDate.toDate());
    const fromDateFormattedBECheck = fromDateFormated.toISOString().split('T')[0];
    const toDateFormattedBECheck = toDateFormated.toISOString().split('T')[0];

    const roomTypeTr = roomType?.includes('Double') ? 'Двойна стая' : "Студио"

    const handleRoomTypeChange = (e) => {
        setRoomTypeValue(e.target.value)
    }
    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        return `${day}.${month}.${year}`;
    };

    const formatCartItems = () => {
        return `Резервация на името на ${firstName} ${surname}. Телефонен номер - ${phoneNumber}. Запазена ${!roomTypeTr ? roomTypeValue : roomTypeTr} за дати - ${formatDate(fromDateFormated)}-${formatDate(toDateFormated)}`
    };

    const returnErrorMessage = () => {
        if (!roomType) {
            return roomTypeValue.toLowerCase().includes('double') ? t('room-availability-error-v1') : t('room-availability-error-v2')
        } else {
            return roomType.toLowerCase().includes('double') ? t('room-availability-error-v1') : t('room-availability-error-v2')
        }
    }
    const form = useRef(null);
    const template_id = 'template_815mpxo';
    const service_id = 'service_221pjsk';
    const public_key = 'uhrEUrVoYqiv4kN7G';

    const handleSubmit = async (e) => {
        e.preventDefault();

        await createBookingCheck({
            start: fromDateFormattedBECheck,
            end: toDateFormattedBECheck,
            title: roomType ? roomType : roomTypeValue
        }).then((res) => {
            if (!res.data.status) {
                setShowErrorMessage(true);
            } else {

                if (form.current) {
                    emailjs
                        .sendForm(service_id, template_id, form.current, {
                            publicKey: public_key,
                        })
                        .then(
                            () => {
                                setRedirectToSuccess(true)
                                console.log('SUCCESS!');
                            },
                            (error) => {
                                console.log('FAILED...', error);
                            },
                        );
                }
            }
        })
    }


    return (
        <Container sx={{ paddingBottom: '0%', background: 'black' }}>

            {/* <Calendar
                plugins={[dayGridPlugin]}
                initialView="dayGridMonth"
                editable={true}
                selectable={true}
                selectMirror={true}
                dayMaxEvents={true}
                select={handleDateSelect}
                weekends={weekendsVisible}
                // initialEvents={INITIAL_EVENTS} // alternatively, use the `events` setting to fetch from a feed
                // eventContent={renderEventContent} // custom render function
                eventClick={handleEventClick}
                eventsSet={handleEvents}
                dateClick={() => alert('DATE CLICK')} */}

            {/* /> */}
            {/* // called after events are initialized/added/changed/removed
          /* you can update a remote database when these fire:
          eventAdd={function(){}}
          eventChange={function(){}}
          eventRemove={function(){}}
            /> */}
            {/* <BookingToolIframe /> */}
            {/* <BookingToolIframe2 />/ */}
            <Typography variant="h4" component="h2" color="#ed6c02" textAlign={'center'} gutterBottom>
                {t('reserve-title')}
            </Typography>
            <Container component="main" maxWidth="sm">
                <Paper elevation={3} sx={{ p: 3, backgroundColor: 'transparent' }}>
                    <Box ref={form} component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
                        <TextField
                            sx={{ display: 'none' }}
                            name='reservation'
                            value={formatCartItems()}
                        />
                        {!roomType &&
                            <FormControl fullWidth sx={{ mb: '4%' }}>
                                <InputLabel color='warning' sx={{ color: 'white' }} id="room-type-label">{t('room-type')}</InputLabel>
                                <Select
                                    placeholder='ADADASD'
                                    labelId='room-type-label'
                                    sx={{
                                        color: 'white',
                                        backgroundColor: 'rgba(119, 119, 119, 0.19)',
                                    }}
                                    color='warning'
                                    variant='outlined'
                                    label={t('reserve-first-name')}
                                    onChange={handleRoomTypeChange}>
                                    <MenuItem value={'Double'}>{t('rooms-double-room-title')}</MenuItem>
                                    <MenuItem value={"Studio"}>{t('rooms-studio-room-title')}</MenuItem>
                                </Select>
                            </FormControl>}
                        <Box component='div' display={'flex'}>

                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    disablePast
                                    closeOnSelect
                                    autoFocus
                                    slotProps={{
                                        inputAdornment: {

                                        },
                                        layout: {
                                            sx: {
                                                color: '#ed6c02',
                                                border: '2px solid #ed6c02', backgroundColor: 'rgba(119, 119, 119, 0.19)',

                                            }
                                        },
                                        textField: {
                                            InputLabelProps: {
                                                sx: {
                                                    color: 'white',
                                                },
                                                color: 'warning',
                                            },
                                            inputProps: {
                                                style: {
                                                    color: 'white'
                                                }
                                            },
                                            focused: false

                                        },

                                        rightArrowIcon: {
                                            sx: {
                                                color: '#ed6c02'
                                            }
                                        },
                                        leftArrowIcon: {
                                            sx: {
                                                color: '#ed6c02'
                                            }
                                        },
                                        yearButton: {
                                            sx: {
                                                ":focus": {
                                                    backgroundColor: '#ed6c02 !important'

                                                }
                                            }
                                        },
                                        monthButton: {
                                            sx: {
                                                ":focus": {
                                                    backgroundColor: '#ed6c02 !important'

                                                },
                                            }
                                        },
                                        day: {
                                            sx: {
                                                ":focus": {
                                                    backgroundColor: '#ed6c02 !important'

                                                },
                                            }
                                        },

                                    }}
                                    className="booking-page-input"
                                    views={['year', 'month', 'day']}
                                    label={t("reserve-from-date")}
                                    value={fromDate}
                                    onChange={(newFromDate) => setFromDate(newFromDate)}
                                />
                            </LocalizationProvider>

                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    disablePast
                                    closeOnSelect

                                    autoFocus
                                    slotProps={{
                                        inputAdornment: {

                                        },
                                        layout: {
                                            sx: {
                                                color: '#ed6c02',
                                                border: '2px solid #ed6c02', backgroundColor: 'rgba(119, 119, 119, 0.19)',

                                            }
                                        },
                                        textField: {
                                            InputLabelProps: {
                                                sx: {
                                                    color: 'white',
                                                },
                                                color: 'warning',
                                            },
                                            inputProps: {
                                                style: {
                                                    color: 'white'
                                                }
                                            },
                                            focused: false

                                        },

                                        rightArrowIcon: {
                                            sx: {
                                                color: '#ed6c02'
                                            }
                                        },
                                        leftArrowIcon: {
                                            sx: {
                                                color: '#ed6c02'
                                            }
                                        },
                                        yearButton: {
                                            sx: {
                                                ":focus": {
                                                    backgroundColor: '#ed6c02 !important'

                                                }
                                            }
                                        },
                                        monthButton: {
                                            sx: {
                                                ":focus": {
                                                    backgroundColor: '#ed6c02 !important'

                                                },
                                            }
                                        },
                                        day: {
                                            sx: {
                                                ":focus": {
                                                    backgroundColor: '#ed6c02 !important'

                                                },
                                            }
                                        },

                                    }}
                                    className="booking-page-input"
                                    color='warning'
                                    minDate={dayjs(today)}
                                    views={['year', 'month', 'day']}
                                    label={t("reserve-to-date")}
                                    value={toDate}
                                    onChange={(newToDate) => setToDate(newToDate)}
                                />
                            </LocalizationProvider>
                        </Box>
                        <TextField style={{ display: 'none' }} value={fromDate} name='from_date' />
                        <TextField style={{ display: 'none' }} value={toDate} name='to_date' />
                        <TextField
                            className="booking-page-input"
                            color='warning'
                            label={t('reserve-first-name')}
                            variant="outlined"
                            fullWidth
                            name="user_name"
                            margin="normal"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            required
                            InputProps={{
                                sx: {
                                    color: 'white'
                                }
                            }}
                            InputLabelProps={{
                                sx: {
                                    color: 'white'
                                }
                            }}
                            sx={{ backgroundColor: '', color: 'white' }}


                        />
                        <TextField
                            label={t('reserve-last-name')}
                            variant="outlined"
                            className="booking-page-input"
                            color='warning'
                            fullWidth
                            InputProps={{
                                sx: {
                                    color: 'white'
                                }
                            }}
                            InputLabelProps={{
                                sx: {
                                    color: 'white'
                                }
                            }}
                            name="user_surname"
                            margin="normal"
                            value={surname}
                            onChange={(e) => setSurName(e.target.value)}
                            required
                            sx={{ backgroundColor: '' }}


                        />
                        <TextField
                            label={t('reserve-phone')}
                            variant="outlined"
                            fullWidth
                            InputProps={{
                                sx: {
                                    color: 'white'
                                }
                            }}
                            InputLabelProps={{
                                sx: {
                                    color: 'white'
                                }
                            }}
                            margin="normal"
                            name='from_phone_number'
                            type='number'
                            color='warning'
                            className="booking-page-input"

                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            required
                            sx={{ backgroundColor: '' }}
                        />

                        <Button
                            type="submit"
                            fullWidth
                            disabled={!phoneNumber || !firstName || !surname}
                            variant="contained"
                            color="warning"
                            sx={{ mt: 3, mb: 2 }}

                        >
                            {t('reserve-button-text')}
                        </Button>
                        {showErrorMessage && <p style={{ color: 'red' }}>{returnErrorMessage()} <span style={{ color: 'white', cursor: 'pointer' }} onClick={() => navigate('/rooms')}>{t('room-availability-error2')}</span></p>}
                    </Box>
                </Paper>
            </Container>
        </Container>
    );
};

export default Booking;
