import {
  AccountCircle,
  EmailOutlined,
  LocationCityOutlined,
  MailOutline,
  PhoneOutlined,
  SubjectOutlined,
} from "@mui/icons-material";
import "./index.scss";
import { Button, Input } from "@mui/material";
import { useTranslation } from "react-i18next";
const ContactsPage = () => {
  const { t } = useTranslation();

  return (
    <div className="contact-us-root">
      <img src="reception1.jpg" alt=""></img>

      <div className="contact-us-page-root-container">
        <div className="contact-us-page-bullets-container">
          <h2 style={{ color: "white" }}>{t("contact-us-title")}</h2>
          <p style={{ color: "white", fontSize: "1rem" }}>
            {t("contact-us-sub-title")}
          </p>
          <div className="contact-us-page-icon-container">
            <PhoneOutlined color="warning" fontSize="large"></PhoneOutlined>
            &nbsp;&nbsp;&nbsp; +359 878 949 291
          </div>
          <div className="contact-us-page-icon-container">
            <MailOutline color="warning" fontSize="large"></MailOutline>
            &nbsp;&nbsp;&nbsp; hotelmagura2019@gmail.com
          </div>
          <div className="contact-us-page-icon-container">
            <LocationCityOutlined
              color="warning"
              fontSize="large"
            ></LocationCityOutlined>
            &nbsp;&nbsp;&nbsp; {t("contact-us-address")}
          </div>
        </div>
        <div className="contact-us-page-enquiry-container">
          <h2 style={{ color: "white", textAlign: "center" }}>
            {t("contact-us-enquiry-title")}
          </h2>
          <form>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <Input
                color="warning"
                className="contact-us-page-enquiry-input"
                startAdornment={
                  <AccountCircle style={{ paddingRight: "2%" }}></AccountCircle>
                }
                placeholder={t("contact-us-input-name")}
              ></Input>
              <Input
                color="warning"
                className="contact-us-page-enquiry-input"
                startAdornment={
                  <EmailOutlined style={{ paddingRight: "2%" }}></EmailOutlined>
                }
                placeholder={t("contact-us-input-email")}
              ></Input>
            </div>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <Input
                color="warning"
                className="contact-us-page-enquiry-input"
                startAdornment={
                  <PhoneOutlined style={{ paddingRight: "2%" }}></PhoneOutlined>
                }
                placeholder={t("contact-us-input-phone")}
              ></Input>
              <Input
                className="contact-us-page-enquiry-input"
                color="warning"
                startAdornment={
                  <SubjectOutlined
                    style={{ paddingRight: "2%" }}
                  ></SubjectOutlined>
                }
                placeholder={t("contact-us-input-subject")}
              ></Input>
            </div>

            <Input
              style={{
                color: "white",
                display: "flex",
                justifyContent: "center",
                backgroundColor: "rgb(119 119 119 / 19%)",
                margin: "1% auto",
                width: "90%",
              }}
              multiline={true}
              color="warning"
              rows={10}
              placeholder={t("contact-us-input-enquiry")}
            ></Input>
            <Button
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "2% auto",
              }}
              type="submit"
              variant="contained"
              color="warning"
            >
              {t("contact-us-button")}
            </Button>
          </form>
        </div>
      </div>
      <div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5936.398190691702!2d25.558797!3d41.931573!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14ad51a0260c821d%3A0x5e46bf4ab0704729!2sHotel%20Magura!5e0!3m2!1sen!2sbg!4v1723986227432!5m2!1sen!2sbg"
          height="600"
          allowfullscreen=""
          style={{
            margin: "2% auto",
            width: "90%",
            border: "1px solid #333",
            display: "flex",
          }}
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
          title="googleLocation"
        ></iframe>
      </div>
    </div>
  );
};

export default ContactsPage;
