import React, { useEffect } from 'react';
import { useTokenExpiration } from './useTokenExpiration';
import { useNavigate } from 'react-router-dom';

const TokenExpirationHandler = ({ token, onTokenExpired }) => {
    const isExpired = useTokenExpiration(token);
    const navigate = useNavigate();

    useEffect(() => {
        if (isExpired) {
            // Handle expired token
            localStorage.removeItem('token');
            if (onTokenExpired) {
                onTokenExpired();
            } else {
                navigate('/', { state: { expired: true } });
            }
        }
    }, [isExpired, navigate, onTokenExpired]);

    return null; // No rendering
};

export default TokenExpirationHandler;
