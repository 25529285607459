import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { useEffect, useState } from 'react';
import { fetchBookings, createBooking, deleteBooking, createBookingCheck } from '../../services/booking';
import { authenticate } from '../../services/auth';
import { useNavigate } from 'react-router-dom';
import TokenExpirationHandler from '../../utils';

const Admin = () => {
    const [weekendsVisible, setWeekendsVisible] = useState(true);
    const [currentEvents, setCurrentEvents] = useState([]);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [credentials, setCredentials] = useState({ username: '', password: '' });
    const navigate = useNavigate();
    const token = localStorage.getItem("token");

    const handleTokenExpired = () => {
        alert("Сесията ви изтече. Моля въведете вашите данни отново.");
        window.location.reload();

        // Additional cleanup or navigation logic if needed
    };
    const handleLogin = async () => {
        try {
            await authenticate(credentials).then(async (res) => {
                window.localStorage.setItem('token', res.token)
                setIsAuthenticated(true)
                await fetchBookings().then((res) => {
                    console.log(res);

                    setCurrentEvents(res)
                }
                )
            }
            );

        } catch (error) {
            console.log(error);

            alert('Invalid username or password');
        }
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCredentials({ ...credentials, [name]: value });
    };



    useEffect(() => {
        // Fetch events from backend on mount
        const loadBookings = async () => {
            try {
                const bookings = await fetchBookings();
                setCurrentEvents(bookings);
            } catch (error) {

                console.error("Error loading bookings:", error);
            }
        };

        loadBookings();
    }, []);


    const handleWeekendsToggle = () => {
        setWeekendsVisible(!weekendsVisible);
    };

    const handleDateSelect = async (selectInfo) => {
        let title = prompt('Моля въведете вида стая - Double / Studio');
        let calendarApi = selectInfo.view.calendar;
        calendarApi.unselect(); // Clear date selection

        if (title) {
            const newEvent = {
                title,
                start: selectInfo.startStr,
                end: selectInfo.endStr,
            };

            try {
                await createBookingCheck(newEvent).then(async (res) => {
                    if (!!res.message) {
                        alert('Няма повече свободни стаи за селектирания период.')
                    } else {
                        setCurrentEvents([...currentEvents, res]);
                        calendarApi.addEvent({
                            ...res,
                        });
                        await fetchBookings()
                    }
                    console.log(res);

                });
            } catch (error) {
                if (error.status === 403) {
                    window.location.reload();

                }
                console.error("Error creating event:", error);
            }
        }
    };

    const handleEventClick = async (clickInfo) => {
        if (window.confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'?`)) {
            try {
                await deleteBooking(clickInfo.event.id).then(async () => {
                    clickInfo.event.remove();
                    await fetchBookings().then((res) => setCurrentEvents(res))

                });
            } catch (error) {
                if (error.status === 403) {
                    window.location.reload();

                }
                console.error("Error deleting event:", error);
            }
        }
    };


    if (!token) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                    backgroundColor: '#f5f5f5',
                }}
            >
                <div
                    style={{
                        padding: '20px',
                        background: '#fff',
                        borderRadius: '10px',
                        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                    <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>Admin Login</h2>
                    <input
                        type="text"
                        name="username"
                        placeholder="Username"
                        value={credentials.username}
                        onChange={handleInputChange}
                        style={{ display: 'block', width: '100%', marginBottom: '10px', padding: '8px' }}
                    />
                    <input
                        type="password"
                        name="password"
                        placeholder="Password"
                        value={credentials.password}
                        onChange={handleInputChange}
                        style={{ display: 'block', width: '100%', marginBottom: '20px', padding: '8px' }}
                    />
                    <button
                        onClick={handleLogin}
                        style={{
                            display: 'block',
                            width: '100%',
                            padding: '10px',
                            backgroundColor: '#007BFF',
                            color: 'white',
                            border: 'none',
                            borderRadius: '5px',
                        }}
                    >
                        Login
                    </button>
                </div>
            </div>
        );
    }



    return (
        <div
            style={{
                background: 'white',
                width: '80%',
                height: '50%',
                textAlign: 'center',
                margin: '0 auto',
            }}
        >
            {token && <TokenExpirationHandler token={token} onTokenExpired={handleTokenExpired} />}

            <FullCalendar
                height={'100vh'}
                expandRows
                handleWindowResize={true}
                eventBackgroundColor="orange"
                eventTextColor="black"
                plugins={[interactionPlugin, dayGridPlugin]}
                initialView="dayGridMonth"
                editable={true}
                selectable={true}
                selectMirror={true}
                dayMaxEvents={true}
                select={handleDateSelect}
                weekends={weekendsVisible}
                eventClick={handleEventClick}
                events={currentEvents}
                themeSystem="Bootstrap 4"
            />
        </div>
    );
};

export default Admin;
