import {
  HomeOutlined,
  LocalParkingOutlined,
  LocationCityOutlined,
  RoomServiceOutlined,
} from "@mui/icons-material";
import "./index.scss";
import { useTranslation } from "react-i18next";

const RowExtras = () => {
  const { t } = useTranslation();

  return (
    <div className="row-extras-root">
      <div className="row-extras-single-container">
        <LocationCityOutlined
          fontSize="large"
          color="warning"
        ></LocationCityOutlined>
        <h2>{t("home-location-title")}</h2>
        <p>
          <strong>{t("home-location-subtitle1")}</strong>
        </p>
        <p>
          <strong>{t("home-location-subtitle2")}</strong>
        </p>
        <p>
          <strong>{t("home-location-subtitle3")}</strong>
        </p>
      </div>
      <div className="row-extras-single-container">
        <LocalParkingOutlined
          color="warning"
          fontSize="large"
        ></LocalParkingOutlined>
        <h2>{t("home-parking-title")}</h2>
        <p>
          <strong>{t("home-parking-subtitle1")}</strong>
        </p>
        <p>
          <strong>{t("home-parking-subtitle2")}</strong>
        </p>
        <p>
          <strong>{t("home-parking-subtitle3")}</strong>
        </p>
      </div>
      <div className="row-extras-single-container">
        <HomeOutlined color="warning" fontSize="large"></HomeOutlined>
        <h2>{t("home-hospitality-title")}</h2>
        <p>
          <strong>{t("home-hospitality-subtitle1")}</strong>
        </p>
        <p>
          <strong>{t("home-hospitality-subtitle2")}</strong>
        </p>
        <p>
          <strong>{t("home-hospitality-subtitle3")} </strong>
        </p>
      </div>
      <div className="row-extras-single-container">
        <RoomServiceOutlined
          fontSize="large"
          color="warning"
        ></RoomServiceOutlined>
        <h2>{t("home-service-title")}</h2>
        <p>
          <strong>{t("home-service-subtitle1")}</strong>
        </p>
        <p>
          <strong>{t("home-service-subtitle2")}</strong>
        </p>
        <p>
          <strong>{t("home-service-subtitle3")}</strong>
        </p>
      </div>
    </div>
  );
};

export default RowExtras;
