import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import en from "./translation/en.json";
import bg from "./translation/bg.json";
i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // backend: {
    //   loadPath: "/src/translation/{{lng}}.json",
    // },
    resources: {
      en: {
        translation: en,
      },
      bg: {
        translation: bg,
      },
    },
    fallbackLng: "bg",
    // please disable debug in production
    debug: false,
    //ns means namespace. It is used to group translations into different files.
    // can have multiple namespaces, in case you want to divide a huge
    // translation into smaller pieces and load them on demand
    interpolation: {
      espaceValue: false,
      formatSeparator: ",",
    },
    react: {
      wait: true,
    },
  });
export default i18n;
