import image1 from "./DoubleRoomImages/1.jpg";
import image2 from "./DoubleRoomImages/2.jpg";
import image3 from "./DoubleRoomImages/3.jpg";
import image4 from "./DoubleRoomImages/4.jpg";
import image5 from "./DoubleRoomImages/5.jpg";
import image6 from "./DoubleRoomImages/6.jpg";
import image7 from "./DoubleRoomImages/7.jpg";
import image8 from "./DoubleRoomImages/8.jpg";
import image9 from "./DoubleRoomImages/9.jpg";
import image10 from "./DoubleRoomImages/10.jpg";
import image11 from "./DoubleRoomImages/11.jpg";
import image12 from "./DoubleRoomImages/12.jpg";
import image13 from "./DoubleRoomImages/13.jpg";
import image14 from "./DoubleRoomImages/14.jpg";
import image15 from "./DoubleRoomImages/15.jpg";

import studioImage1 from "./StudioImages/1.jpg";
import studioImage2 from "./StudioImages/2.jpg";
import studioImage3 from "./StudioImages/3.jpg";
import studioImage4 from "./StudioImages/4.jpg";
import studioImage5 from "./StudioImages/5.jpg";
import studioImage6 from "./StudioImages/6.jpg";
import studioImage7 from "./StudioImages/7.jpg";
import studioImage8 from "./StudioImages/8.jpg";
import studioImage9 from "./StudioImages/9.jpg";
import studioImage10 from "./StudioImages/10.jpg";
import studioImage11 from "./StudioImages/11.jpg";
import studioImage12 from "./StudioImages/12.jpg";
import studioImage13 from "./StudioImages/13.jpg";
import studioImage14 from "./StudioImages/14.jpg";
import studioImage15 from "./StudioImages/15.jpg";
import studioImage16 from "./StudioImages/16.jpg";
import studioImage17 from "./StudioImages/17.jpg";
export const roomsData = [
  {
    id: 1,
    type: "Double room",
    extras: [
      { id: 1, type: "TV" },
      { id: 2, type: "Refrigerator" },
    ],
    info: "Our cozy Double Room is perfect for couples or solo travelers looking for comfort and convenience. This room features a comfortable double bed, a private bathroom with complimentary toiletries, and basic amenities such as a flat-screen TV, free Wi-Fi, and a desk. Simple yet functional, it’s an ideal choice for a pleasant stay.",
    bullets: [
      "Clean and coisy furniture",
      "Comfortable double bed",
      "Private bathroom",
      "TV",
      "A/C",
      "Free Wi-Fi and a desk",
      "Refridgerator",
    ],
    images: [
      image1,
      image2,
      image3,
      image4,
      image5,
      image6,
      image7,
      image8,
      image9,
      image10,
      image11,
      image12,
      image13,
      image14,
      image15,
    ],
  },
  {
    id: 2,
    type: "Studio",
    extras: [
      { id: 1, type: "TV" },
      { id: 2, type: "Refrigerator" },
    ],
    info: "Our Studio Room for Four is the ideal choice for families or groups looking for budget-friendly accommodation. This spacious studio features a double bed, a sofa bed, and a private bathroom. Guests can relax in the seating area, enjoy entertainment on the flat-screen TV, or stay connected with free Wi-Fi. With convenient amenities like a small dining table and kitchenette facilities, it’s a home away from home.",
    bullets: [
      "Clean and coisy furniture",
      "Comfortable double bed",
      "Comfortable sleeping sofa",
      "Private bathroom",
      "TV",
      "A/C",
      "Free Wi-Fi and a desk",
      "Refridgerator",
    ],
    images: [
      studioImage5,
      studioImage3,
      studioImage4,
      studioImage1,
      studioImage2,
      studioImage6,
      studioImage7,
      studioImage8,
      studioImage9,
      studioImage10,
      studioImage11,
      studioImage12,
      studioImage13,
      studioImage14,
      studioImage15,
      studioImage16,
      studioImage17,
    ],
  },
];

// test
