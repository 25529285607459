import { Carousel } from "react-responsive-carousel";
import Slider from "react-slick";
import "./index.scss";
import { Box, Button, Grid } from "@mui/material";
import RowExtras from "../../components/Home/RowExtras";
import { ArrowDownwardOutlined, RoomOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
const HomePage = () => {
  const { t } = useTranslation();

  const createCarouselItemImage = (index, options = {}) => (
    <div className="carusel-image" key={index}>
      <img src={`${index}.jpg`} alt="" />
    </div>
  );

  const baseChildren = (
    <div>
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13].map(createCarouselItemImage)}
    </div>
  );
  var settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    fade: true,
    autoplaySpeed: 5000,
    cssEase: "linear",
  };
  return (
    <div className="home-page-root">
      <ArrowDownwardOutlined
        color="warning"
        onClick={() => {
          window.scrollTo({ behavior: "smooth", top: 650 });
        }}
        className="arrow-go-down"
      />
      <span style={{ color: "white" }}>{t("home-click")}</span>
      <Slider className="slider" {...settings}>
        {baseChildren.props.children}
      </Slider>
      <RowExtras />
      <div className="home-page-rooms">
        <Box sx={{ flexGrow: 1 }}>
          <Grid className="grid-container-top" container spacing={1}>
            <Grid item xs={4}>
              <Box className="grid-box">
                <img
                  className="grid-box-image-top-left"
                  alt=""
                  src="reception.jpg"
                ></img>
                <div className="grid-content">{t("home-secure")}</div>
              </Box>
            </Grid>
            <Grid item xs={8}>
              <Box className="grid-box">
                <img
                  className="grid-box-image-top-right"
                  alt=""
                  src="cooridor.jpg"
                ></img>
                <div className="grid-content">{t("home-free-parking")}</div>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={1} className="grid-container-bottom">
            <Grid item xs={3}>
              <Box className="grid-box">
                <img
                  className="grid-box-image-bottom-left"
                  alt=""
                  src="9.jpg"
                ></img>{" "}
                <div className="grid-content-bottom">{t("home-cosiness")}</div>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box className="grid-box">
                <img
                  className="grid-box-image-bottom-left"
                  alt=""
                  src="12.jpg"
                ></img>{" "}
                <div className="grid-content-bottom">{t("home-rate")}</div>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box className="grid-box">
                <img
                  className="grid-box-image-bottom-left"
                  alt=""
                  src="room.jpg"
                ></img>{" "}
                <div className="grid-content-bottom">{t("home-price")}</div>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box className="grid-box">
                <img
                  className="grid-box-image-bottom-right"
                  alt=""
                  src="7.jpg"
                ></img>{" "}
                <div className="grid-content-bottom">{t("home-furniture")}</div>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </div>
      <div className="home-page-introduction">
        <div className="home-page-introduction-left-side">
          <h1>{t("home-info-title")}</h1>
          <p>{t("home-info-text")}</p>
        </div>
        <div className="home-page-introduction-right-side">
          <div className="flip-box-container-1">
            <div className="flip-box-inner">
              <div className="flip-box-front">
                <img
                  className="home-page-introduction-right-side-img-1"
                  src="hotel.jpg"
                  alt=""
                ></img>
              </div>
              <div className="flip-box-back">
                <img
                  className="home-page-introduction-right-side-img-1"
                  src="coridor.jpg"
                  alt=""
                ></img>
              </div>
            </div>
          </div>
          <div className="flip-box-container">
            <div className="flip-box-inner">
              <div className="flip-box-front">
                <img
                  className="home-page-introduction-right-side-img-2"
                  src="2.jpg"
                  alt=""
                ></img>
              </div>
              <div className="flip-box-back">
                <img
                  className="home-page-introduction-right-side-img-2"
                  src="3.jpg"
                  alt=""
                ></img>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
