import api from '../services/index'
const API_BASE_URL = '/api/auth';

export const authenticate = async (body) => {
    try {
        const response = await api.post(API_BASE_URL, body);
        return response.data;
    } catch (error) {
        console.error("Error fetching bookings:", error);
        throw error;
    }
};

