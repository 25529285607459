import { Button } from "@mui/material";
import "./index.scss";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
const RoomsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="rooms-page-root">
      <img src="roompage.jpg" alt="" className="rooms-page-img"></img>
      <div>
        <h1 className="rooms-page-title">{t("rooms-title")}</h1>
        <div className="rooms-page-container">
          <div className="rooms-page-first-room">
            {" "}
            <div className="flip-box-rooms-container-1">
              <div className="flip-box-rooms-inner">
                <div className="flip-box-rooms-front">
                  <img
                    className="rooms-page-img-1"
                    src="room1.jpg"
                    alt=""
                  ></img>
                </div>
                <div className="flip-box-rooms-back">
                  <img
                    className="rooms-page-img-1"
                    src="room2.jpg"
                    alt=""
                  ></img>
                </div>
              </div>
            </div>
            <div className="rooms-page-text-info-container">
              <h2 style={{ color: "white" }}>{t("rooms-double-room-title")}</h2>
              <span style={{ color: "white" }}>
                {t("rooms-double-room-description")}
              </span>
              <Button
                className="rooms-page-learn-more-button"
                color="warning"
                variant="contained"
                onClick={() => {
                  navigate(`/rooms/${1}`);
                }}
              >
                {t("rooms-learn-more")}
              </Button>
            </div>
          </div>
          <div className="rooms-page-second-room-container">
            {" "}
            <div className="flip-box-rooms-container-1">
              <div className="flip-box-rooms-inner">
                <div className="flip-box-rooms-front">
                  <img
                    className="rooms-page-img-2"
                    src="studio2.jpg"
                    alt=""
                  ></img>
                </div>
                <div className="flip-box-rooms-back">
                  <img
                    className="rooms-page-img-2"
                    src="studio1.jpg"
                    alt=""
                  ></img>
                </div>
              </div>
            </div>
            <div className="rooms-page-text-info-container">
              <h2 style={{ color: "white" }}>{t("rooms-studio-room-title")}</h2>
              <span style={{ color: "white" }}>
                {t("rooms-studio-room-description")}
              </span>
              <Button
                className="rooms-page-learn-more-button"
                color="warning"
                variant="contained"
                onClick={() => {
                  navigate(`/rooms/${2}`);
                }}
              >
                {t("rooms-learn-more")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoomsPage;
