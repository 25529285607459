import { Typography, Button, Container, Box } from '@mui/material';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';

const ThankYou = () => {
    const navigate = useNavigate();

    const handleBackToProducts = () => {
        navigate('/');
    };

    return (
        <Container component="main" maxWidth="sm" sx={{ textAlign: 'center', mt: 8 }}>
            <Box sx={{ p: 3, backgroundColor: 'transparent', borderRadius: 2, }}>
                <Typography variant="h4" gutterBottom sx={{ color: 'white' }}>
                    {t('success-title')}
                </Typography>
                <Typography variant="h6" gutterBottom sx={{ color: 'white' }}>
                    {t('success-sub-title')}              </Typography>
                <Typography variant="body2" sx={{ color: 'warning', mt: 2 }}>
                    <Button
                        variant="contained"
                        color="warning"
                        onClick={handleBackToProducts}
                        sx={{ mt: 2 }}
                    >
                        {t('success-button')}
                    </Button>
                </Typography>
            </Box>
        </Container>
    );
};

export default ThankYou;
