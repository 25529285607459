import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import { Link, useNavigate } from "react-router-dom";
import "./index.scss";
import {
  CheckOutlined,
  MailOutline,
  PhoneOutlined,
  ReviewsOutlined,
} from "@mui/icons-material";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { Select } from "@mui/material";
const settings = ["Profile", "Account", "Dashboard", "Logout"];

const Header = () => {
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const [anchorPcElNav, setAnchorPcElNav] = React.useState(null);
  const handleOpenNavMenu = (event) => {
    console.log(event.currentTarget);
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenPcMenu = (e) => {
    console.log(e.currentTarget);
    setAnchorPcElNav(e.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseNavPcMenu = () => {
    setAnchorPcElNav(null);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const { i18n, t } = useTranslation();

  const pages = [
    // "Prices",
    t('header-rooms'),
    t('header-contacts'),
    // "Destinations"
  ];
  React.useEffect(() => {
    if (localStorage.getItem("i18nextLng")?.length > 2) {
      i18next.changeLanguage("en");
    }
  }, []);
  const handleLanguageChange = (e) => {
    i18n.changeLanguage(e.target.value);
  };

  const returnPageLink = (page) => {
    if (page.toLowerCase() === "стаи" || page.toLowerCase() === 'rooms') {
      return 'rooms'
    } else {
      return 'contacts'
    }
  }
  return (
    <>
      <header
        style={{
          display: window.location.href.includes('admin') ? 'none':"flex",
          justifyContent: "flex-end",
          flexDirection: "row",
          zIndex: 999,
          position: "relative",
          boxShadow: "none",
        }}
      >
        <div className="icon-first-header-container">
          <PhoneOutlined color="warning"></PhoneOutlined>
          <a
            style={{ textDecoration: "none", color: "whitesmoke" }}
            href="tel:+359878949291"
          >
            . +359878949291
          </a>
        </div>
        <div
          onClick={() =>
            (window.location.href = "mailto:hotelmagura2019@gmail.com")
          }
          className="icon-first-header-container"
        >
          <MailOutline color="warning"></MailOutline>{" "}
          <a style={{ textDecoration: "none", color: "whitesmoke" }}>
            . hotelmagura2019@gmail.com
          </a>
        </div>
        <div
          style={{ display: window.innerWidth <= 767 ? "none" : "flex" }}
          className="icon-first-header-container"
        >
          <CheckOutlined color="warning"></CheckOutlined>
          <text style={{ alignSelf: "center" }}>. {t('online-checkin')}</text>
        </div>
        <div className="icon-first-header-container">
          <ReviewsOutlined color="warning"></ReviewsOutlined>{" "}
          <a
            className="rate-your-stay"
            target="_blank"
            style={{ textDecoration: "none", color: "whitesmoke" }}
            rel="noreferrer"
            href="https://www.google.com/travel/search?gsas=1&ts=EgAaHBIaEhQKBwjoDxAJGBASBwjoDxAJGBEYATICEAA&qs=MjZDaVFJcVk3Qmc2dnByNk5lRVAtTjRNLTZnNXJrdUFFYURTOW5MekV4Wm5Jek5uTXhiWGdRQVE4AA&ap=ugEHcmV2aWV3cw&hl=en&ved=0CAAQ5JsGahcKEwjQwaHyzv6HAxUAAAAAHQAAAAAQCw"
          >
            . {t('rate-your-stay')}
          </a>
        </div>
        <select
          className="select-language"
          value={localStorage.getItem("i18nextLng")}
          onChange={handleLanguageChange}
        >
          <option className="option-language" value="en">
            EN
          </option>
          <option className="option-language" value="bg">
            BG
          </option>
        </select>
        {/* <Select
          className="select-language"
          value={localStorage.getItem("i18nextLng")}
          onChange={handleLanguageChange}
          
        >
          <MenuItem className="option-language" value={"en"}>
            EN
          </MenuItem>
          <MenuItem value={"bg"}>BG</MenuItem>
        </Select> */}
      </header>
      <AppBar
        sx={{
          display: window.location.href.includes('admin') ? 'none':"flex",

          background: "#333333ad",
          zIndex: "999",
          position: "relative",
          boxShadow:
            "0px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 10px 10px 0px rgba(0, 0, 0, 0.12)",
        }}
        position="static"
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <img
              className="navbar-img"
              style={{
                cursor: "pointer",
                position: "absolute",
                left: "0",
                bottom: "10%",
              }}
              onClick={() => navigate("/")}
              height={100}
              src="/logo.png"
              alt=""
            />
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "flex", md: "none" },
                justifyContent: "flex-end",
              }}
            >
              <Button
                style={{
                  height: "20px",
                  width: "20%",
                  fontSize: "0.6rem",
                  alignSelf: "center",
                }}
                color="warning"
                variant="contained"
                onClick={() => navigate('/booking')}
              >
                {t("reserve-button-text")}
              </Button>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">
                      <Link
                        style={{
                          display: "block",
                          width: "100%",
                          textDecoration: "none",
                          color: "black",
                        }}
                        onClick={handleCloseNavMenu}
                        to={page.toLowerCase()}
                      >
                        {returnPageLink(page)}
                      </Link>
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                justifyContent: "flex-end",
              }}
            >
              {/* <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar1"
              aria-haspopup="true"
              onClick={handleOpenPcMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar1"
              anchorEl={anchorPcElNav}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              keepMounted
              transformOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              componentsProps={{
                root: {
                  style: {
                    top: "6%",
                    left: "2%",
                  },
                },
              }}
              closeAfterTransition={true}
              open={Boolean(anchorPcElNav)}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavPcMenu}>
                  <Typography textAlign="center">
                    <Link
                      style={{
                        display: "block",
                        width: "100%",
                        textDecoration: "none",
                        color: "black",
                      }}
                      onClick={handleCloseNavPcMenu}
                      to={page.toLowerCase()}
                    >
                      {page}
                    </Link>
                  </Typography>
                </MenuItem>
              ))}
            </Menu> */}
              {pages.map((page) => (
                <Button
                  key={page}
                  onClick={handleCloseNavMenu}
                  sx={{
                    my: 2,
                    color: "white",
                    display: "block",
                    cursor: "auto ",
                    alignSelf: "center",
                    height: "50%",
                    marginRight: "0.5%",
                    backgroundColor: window.location.href.includes(
                      page.toLowerCase()
                    )
                      ? "#858080c7"
                      : "transparent",
                    ":hover": {
                      background: "#333",
                    },
                  }}
                // variant={
                //   window.location.href.includes(page.toLocaleLowerCase())
                //     ? "contained"
                //     : "text"
                // }
                >
                  <Link
                    style={{
                      display: "block",
                      width: "100%",
                      textDecoration: "none",
                      color: "white",
                      cursor: "pointer",
                    }}
                    //   onClick={handleCloseNavMenu}
                    to={returnPageLink(page)
                    }
                  >
                    {page}
                  </Link>
                </Button>
              ))}
              <Button
                style={{ height: "50%", alignSelf: "center" }}
                color="warning"
                variant="contained"
                onClick={() => navigate('/booking')}

              >
                {t("reserve-button-text")}
              </Button>
            </Box>

            {/* <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box> */}
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};

export default Header;
