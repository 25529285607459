import { useJwt } from 'react-jwt';

/**
 * Custom hook to handle token expiration logic.
 * @param {string} token The JWT token
 * @returns {boolean} Whether the token is expired
 */
export const useTokenExpiration = (token) => {
    const { isExpired } = useJwt(token);
    return isExpired;
};
