import axios from 'axios';
import TokenExpirationHandler from '../utils';

// Create an Axios instance
const api = axios.create({
    baseURL: 'https://magura-hotel-c33dde4eec95.herokuapp.com', // Set your backend base URL
});

// Add a request interceptor 
api.interceptors.request.use(
    (config) => {
        // Get the token from localStorage
        const token = localStorage.getItem('token');
        if (token) {
            // if (TokenExpirationHandler(token)) {
            //     // Token is expired, remove it and redirect
            //     localStorage.removeItem('token');
            //     window.location.href = '/'; // Redirect to login page
            //     return Promise.reject(new Error('Token expired'));
            // }
            // Add the token to the Authorization header
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        // Handle the error
        return Promise.reject(error);
    }
);

export default api;
